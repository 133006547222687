<!-- eslint-disable vue/max-attributes-per-line -->
<template>
	<section class="section">
		<div class="content">
			<h3 class="page-title">
				Политика конфиденциальности для интернет-ресурсов благотворительного фонда «система»
			</h3>

			<div class="text dark sm">
				<br>
				<p><b>1. Общие положения</b></p>
				<p>
					1.1. Настоящая Политика конфиденциальности для Интернет-ресурсов (далее – Политика) действует в отношении всей информации, которую Благотворительный фонд «Система» (далее – Фонд) может получить о физическом лице (далее – Пользователь) во время использования им следующих Интернет-ресурсов:
					<a href="https://bf.sistema.ru/" target="_blank">bf.sistema.ru</a>,
					<a href="https://dobro.sistema.news/" target="_blank">dobro.sistema.news</a>,
					<a href="https://lift-bf.ru/">lift-bf.ru</a>,
					<a href="https://sport.bf.sistema.ru/" target="_blank">sport.bf.sistema.ru</a> (далее – Сайт).
				</p>
				<p>
					1.2. Целью настоящей Политики является обеспечение надлежащей защиты информации о Пользователях, в том числе их персональных данных, от несанкционированного доступа и разглашения.
				</p>
				<p>
					1.3. Отношения, связанные со сбором, хранением, распространением и защитой информации о Пользователях Сайта, регулируются настоящей Политикой, иными нормативными документами Фонда и действующим законодательством Российской Федерации.
				</p>
				<p>
					1.4. Настоящая Политика вступает в силу с момента ее утверждения Президентом Фонда и размещения на Сайте Фонда. Использование Пользователем сайта и(или) регистрация Пользователя на Сайте Фонда означает согласие с настоящей Политикой и условиями обработки персональных данных Пользователя.
				</p>
				<p>
					Заполняя соответствующие формы и/или отправляя свои персональные данные на Сайте, Пользователь выражает свое согласие с данной Политикой. Пользователь самостоятельно принимает решение о предоставлении своих персональных данных и дает согласие свободно, своей волей и в своем интересе.
				</p>
				<p>
					Кроме того, на Сайте проводится сбор информации об использовании Сайта без непосредственного предоставления Пользователем подобной информации (например, посещенные страницы, дата и время доступа, избранный контент, операционная система, тип используемого браузера, тип используемого оборудования и т.д.). Такая информация может быть получена с помощью различных методов, например, файлов cookies.
				</p>
				<p>
					1.5. Настоящая Политика применяется только к Сайтам Фонда. Фонд не контролирует и не несет ответственность за сайты третьих лиц, на которые Пользователь может перейти по ссылкам, доступным на Сайте Фонда.
				</p>
				<br>
				<p><b>2. Информация, на которую распространяется политика конфиденциальности</b></p>
				<p>
					2.1. В целях настоящей Политики используются следующие термины и определения:
				</p>
				<ul>
					<li>
						2.1.1. Администрация сайта – сотрудники Фонда, уполномоченные на осуществление управления Сайтом Фонда и другие действия, связанные с его использованием. Администрация Сайта действует от имени Фонда.
					</li>
					<li>
						2.1.2. Персональные данные - любая информация, относящаяся прямо или косвенно к определенному или определяемому физическому лицу (субъекту персональных данных) в соответствии с Федеральным законом «О персональных данных»;
					</li>
					<li>
						2.1.3. Обезличивание персональных данных - действия, в результате которых невозможно определить принадлежность персональных данных конкретному субъекту персональных данных;
					</li>
					<li>
						2.1.4. Обработка персональных данных - любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.
					</li>
					<li>
						2.1.5. Пользователь – любое физическое лицо, использующее Сайт. 
					</li>
					<li>
						2.1.6. Регистрация – заполнение Пользователем регистрационной формы, расположенной на Сайте, путем указания необходимых сведений и выбора Логина и пароля.
					</li>
					<li>
						2.1.7. Регистрационная форма – форма, расположенная на Сайте, которую Пользователь должен заполнить для прохождения регистрации на Сайте.
					</li>
					<li>
						2.1.8. IP-адрес – уникальный идентификатор (адрес) устройства (обычно компьютера), подключённого к локальной сети или сети Интернет.
					</li>
					<li>
						2.1.9. Cookies - небольшой фрагмент данных, отправленный веб-сервером и хранимый на компьютере Пользователя, который веб-клиент или веб-браузер каждый раз пересылает веб-серверу в HTTP-запросе при попытке открыть страницу соответствующего Сайта.
					</li>
					<li>
						2.1.10. Информация — сведения (сообщения, данные) независимо от формы их представления.
					</li>
				</ul>	
				<p>
					2.2. В соответствии с настоящей Политикой к информации о Пользователях относятся:
				</p>
				<ul>
					<li>
						2.2.1. Стандартные данные, автоматически получаемые http-сервером при доступе к Сайту (IP-адрес хоста, адрес запрошенного ресурса, время, тип и информация о браузере, пославшем запрос Интернет-ресурса, вид операционной системы пользователя, адрес страницы, с которой был осуществлен переход на Сайт);
					</li>
					<li>
						2.2.2. Персональные данные, предоставленные Пользователем в процессе заполнения регистрационной формы на Сайте (фамилия, имя, адрес электронной почты и т.д.); 
					</li>
					<li>
						2.2.3. Данные, предоставленные Пользователем в процессе заполнения электронных форм (анкет, заявок на участие в конкурсах, мероприятиях Фонда и т.д.);
					</li>
					<li>
						2.2.4. Персональные данные, предоставляемые Пользователем при перечислении пожертвований через Сайт Фонда (фамилия, имя, отчество, адрес, данные банковской карты, адрес электронной почты, номер телефона).
					</li>
				</ul>
			
				<br>
				<p><b>3. Цели обработки персональных данных</b></p>
				<p>
					3.1. Администрация Сайта осуществляет обработку информации о Пользователях, в том числе их персональных данных, в целях: 
				</p>
				<ul>
					<li>3.1.1. Идентификации Пользователя, зарегистрированного на Сайте.</li>
					<li>3.1.2. Предоставления Пользователю доступа к персонализированным ресурсам Сайта.</li>
					<li>
						3.1.3. Установления с Пользователем обратной связи, включая направление уведомлений, запросов, касающихся использования Сайта, обработка запросов от Пользователя.
					</li>
					<li>3.1.4. Осуществления обратной связи с Пользователем, оставившем заявку на участие в конкурсах и мероприятиях Фонда.</li>
					<li>3.1.5. Определения места нахождения Пользователя для обеспечения безопасности, предотвращения мошенничества.</li>
					<li>3.1.6. Подтверждения достоверности и полноты информации, предоставляемой Пользователем.</li>
					<li>3.1.7. Создания учетной записи для размещения информации (комментариев, статей и пр.) с согласия Пользователя.</li>
					<li>
						3.1.8. Осуществления пожертвований, с использованием Сайта, путем заключения договора пожертвования, стороной которого является Пользователь (субъект персональных данных).
					</li>
					<li>3.1.9. Предоставления Пользователю технической поддержки при возникновении проблем, связанных с использованием Сайта.</li>
					<li>3.1.10. Предоставления Пользователю с его согласия, информации о деятельности Фонда, новостной рассылки и иных сведений.</li>
					<li>3.1.11. Проведения статистических и иных исследований, при условии обезличивания данных Пользователя.</li>
				</ul>
				<p>
					3.2. Персональная и техническая информация о Пользователе собирается и обрабатывается исключительно в целях обеспечения уставной деятельности Фонда, без ограничения срока и в порядке, предусмотренном настоящей Политикой и условиями обработки персональных данных.
				</p>			
			
				<br>
				<p><b>4. Сбор, хранение, защита, обработка, распространение информации о пользователях</b></p>
				<p>
					4.1. Администрация Сайта осуществляет сбор информации о пользователях в соответствии с целями, определенными разделом 3 настоящей Политики.
				</p>
				<p>
					4.2. Администрация Сайта не проверяет достоверность персональных данных, предоставляемых Пользователем и не несет ответственность за достоверность собираемой информации о Пользователях.
				</p>
				<p>4.3. Обработка персональных данных осуществляется на основе принципов:</p>
				<ul>
					<li>4.3.1. Законности целей и способов обработки персональных данных.</li>
					<li>4.3.2. Добросовестности.</li>
					<li>4.3.3. Соответствия целей обработки персональных данных целям, заранее определенным и заявленным при сборе персональных данных.</li>
					<li>4.3.4. Соответствия объема и характера обрабатываемых персональных данных, способов обработки персональных данных целям обработки персональных данных. </li>
				</ul>
				<p>
					4.4. Персональные данные Пользователей хранятся исключительно на электронных носителях и обрабатываются с использованием автоматизированных систем, за исключением случаев, когда неавтоматизированная обработка персональных данных необходима в связи с исполнением требований законодательства Российской Федерации.
				</p>
				<p>
					4.5. Персональные данные Пользователей не передаются каким-либо третьим лицам без согласия Пользователя, за исключением случаев, прямо предусмотренных законодательством Российской Федерации.
				</p>
				<p>4.6. Информация о Пользователях может быть передана лицам, не связанным с Сайтом Фонда, если это необходимо:</p>
				<ul>
					<li>4.6.1. Для соблюдения законодательства Российской Федерации, нормативно-правового акта, исполнения решения суда.</li>
					<li>4.6.2. Для устранения технических неисправностей в работе Сайта.</li>
				</ul>
				<p>4.7. Для предоставления информации на основании запроса уполномоченных государственных органов. На Сайте осуществляется хранение информации о Пользователях в соответствии с целями, определенными настоящей Политикой.</p>
				<p>
					4.8. Вся собранная, хранящаяся и обработанная на Сайте информация о Пользователях считается информацией ограниченного доступа, если иное не установлено законодательством Российской Федерации или настоящей Политикой.
				</p>
				<p>4.9. Администрация Сайта предпринимает все разумные меры по защите информации о Пользователях от уничтожения, искажения или разглашения.</p>
				<p>
					4.10. Персональные данные, разрешённые к обработке в рамках настоящей Политики, предоставляются Пользователем путём заполнения соответствующей информации на Сайте и могут включать в себя следующую информацию:
				</p>
				<ul>
					<li>4.10.1. Фамилия, имя, отчество.</li>
					<li>4.10.2. Дата рождения, пол.</li>
					<li>4.10.3. Паспортные данные.</li>
					<li>4.10.4. Контактная информация (адрес электронной почты, номер телефона).</li>
					<li>4.10.5. Место работы/учебы.</li>
					<li>4.10.6. Сведения об образовании</li>
					<li>4.10.7. Сведения о месте проживания.</li>
					<li>4.10.8. Фотографическое изображение.</li>
				</ul>
				<p>4.11. Фонд не несет ответственности за достоверность полученной от Пользователя информации.</p>
				<p>4.12. В том случае, если Пользователь предоставляет Фонду персональные данные третьих лиц, он несет ответственность за информирование третьей стороны об использовании таких данных и за получение соответствующего согласия.</p>
				<p>4.13. При обработке информации, полученной при осуществлении пожертвований, с использованием Сайта, Фонд предоставляет Пользователю возможность выбора между несколькими способами платежа. При выборе способа платежа, включая соответствующий платежный сервис и (или) платежную систему, Пользователь ориентируется на собственные предпочтения и степень доверия к тому или иному платежному сервису и (или) платежной системе. Безопасность представленных на Сайте платежных сервисов и (или) платежных систем подтверждается соответствующими сертификатами соответствия стандартам безопасности, а также положениями, политиками и пользовательскими соглашениями сервисов и (или) платежных систем, с которыми Пользователь самостоятельно ознакамливается при осуществлении платежа и (или) на официальных сайтах платежных сервисов и платежных систем.</p>
				<p>4.14. Информация, предоставляемая Пользователем при осуществлении пожертвований на Сайте через платежные шлюзы различных платежных сервисов и систем, передается без исправлений и искажений на сервера платежных сервисов (систем), не используется и не хранится Фондом.</p>
				<p>4.15. Данные платежной карты и (или) иные данные, необходимые для аутентификации Пользователя с целью проведения платежа, Пользователь вводит на страницах платежных сервисов (систем) или в специальной защищенной всплывающей форме. Данная информация не доступна Фонду и не подлежит какой-либо обработке.</p>
				<p>4.16. Обработка персональных данных Пользователя осуществляется без ограничения срока, любым законным способом, в том числе в информационных системах персональных данных с использованием средств автоматизации или без использования таких средств.</p>

				<br>
				<p><b>5. Права пользователя</b></p>
				<p>
					5.1. Пользователь имеет право на:
				</p>
				<ul>
					<li>5.1.1. Получение сведений о том, хранится ли на Сайте информация, содержащая персональные данные Пользователя.</li>
					<li>5.1.2. Удаление хранящихся персональных данных.</li>
					<li>5.1.3. Уточнение или исправление хранящихся персональных данных.</li>
				</ul>
				<p>
					5.2.При возникновении вопросов, а также для получения сведений относительно обработки персональных данных, удаления, уточнения и исправления персональных данных, Пользователю необходимо отправить запрос на адрес электронной почты Администрации сайта: <a href="mailto:info@bf.sistema.ru">info@bf.sistema.ru</a>.
				</p>

				<br>
				<p><b>6. Обязательства сторон</b></p>
				<p>6.1. Пользователь обязан:</p>
				<ul>
					<li>6.1.1. Предоставить информацию о персональных данных, необходимую для пользования Сайтом.</li>
					<li>6.1.2. Обновить, дополнить предоставленную информацию о персональных данных в случае изменения данной информации.</li>
				</ul>
				<p>
					6.2. Администрация Сайта обязана:
				</p>
				<ul>
					<li>6.2.1. Использовать полученную информацию исключительно для целей, указанных в разделе 3 настоящей Политики.</li>
					<li>6.2.2. Обеспечить хранение конфиденциальной информации в тайне, не разглашать без предварительного письменного разрешения Пользователя, а также не осуществлять продажу, обмен, опубликование, либо разглашение иными возможными способами переданных персональных данных Пользователя, за исключением случаев, предусмотренных п.п. 4.5 – 4.6 настоящей Политики.</li>
					<li>6.2.3. Принимать меры предосторожности для защиты конфиденциальности персональных данных Пользователя согласно порядку, обычно используемого для защиты такого рода информации в существующем деловом обороте.</li>
					<li>6.2.4. Осуществить блокирование персональных данных, относящихся к соответствующему Пользователю, с момента обращения или запроса Пользователя или его законного представителя либо уполномоченного органа по защите прав субъектов персональных данных на период проверки, в случае выявления недостоверных персональных данных или неправомерных действий.</li>
					<li>6.2.5. Предоставить Пользователю по его письменному запросу информацию, касающейся обработки его персональных данных, если такое право не ограничено действующим законодательством.</li>
				</ul>
			
			

				<br>
				<p><b>7. Ответственность сторон</b></p>
				<p>
					7.1. Администрация Сайта, не исполнившая свои обязательства, несёт ответственность в связи с неправомерным использованием персональных данных, в соответствии с законодательством Российской Федерации, за исключением случаев, предусмотренных п. 4.5., настоящей Политики.
				</p>
				<p>
					7.2. В случае утраты или разглашения персональных данных Пользователя Администрация Сайта не несёт ответственность, если данная информация:
				</p>
				<ul>
					<li>7.2.1. Стала публичным достоянием до её утраты или разглашения.</li>
					<li>7.2.2. Была получена от третьих лиц до момента её получения Администрацией Сайта.</li>
					<li>7.2.3. Была разглашена с согласия Пользователя.</li>			
				</ul>
				<p>7.3. Пользователь несет полную ответственность за соблюдение требований действующего законодательства Российской Федерации, в том числе законодательства о рекламе, о защите авторских и смежных прав, об охране товарных знаков и знаков обслуживания, но не ограничиваясь перечисленным, включая полную ответственность за содержание и форму материалов.</p>
				<p>7.4. Пользователь признает, что ответственность за любую информацию (в том числе, но не ограничиваясь: файлы с данными, тексты и т. д.), к которой он может иметь доступ как к части Сайта, несет лицо, предоставившее такую информацию.<br>Пользователь соглашается, что информация, предоставленная ему как часть Сайта, может являться объектом интеллектуальной собственности, права на который защищены и принадлежат другим Пользователям, которые размещают такую информацию на Сайте.</p>
				<p>7.5. Любое использование, копирование материалов или подборки материалов Cайта, элементов дизайна и оформления допускается лишь с разрешения Фонда и только со ссылкой на Сайт.<br>В отношении текстовых материалов (статей, публикаций), находящихся в свободном публичном доступе на Сайте, допускается их распространение при условии, что будет дана ссылка на Сайт.</p>



				<br>
				<p><b>8. Заключительные положения</b></p>
				<p>
					8.1. Администрация Сайта рассматривает обращения, связанные с настоящей Политикой, включая запросы пользователей относительно использования их персональных данных, по адресу электронной почты: <a href="mailto:info@bf.sistema.ru">info@bf.sistema.ru</a>.
				</p>
				<p>8.2. Настоящая Политика является открытым и общедоступным документом.</p>
				<p>8.3. Действующая редакция Политики в сети Интернет имеет постоянный адрес: bf.sistema.ru.</p>
				<p>
					8.4. Сайт Фонда может содержать ссылки на другие сайты и приложения, и другие сайты и приложения могут содержать ссылки на Сайт Фонда. Фонд не несет ответственности за информацию, представленную на таких сайтах и в таких приложениях. При использовании сторонних сайтов и приложений Пользователь должен руководствоваться применимыми правилами пользования соответствующих сторонних сайтов и приложений.
				</p>
				<p>8.5. Фонд вправе вносить изменения (дополнения) в настоящую Политику без согласия и уведомления Пользователя.<br>Указанные изменения вступают в силу с момента их публикации на Сайте. Использование Сайта после момента публикации новой редакции Политики означает согласие Пользователя с такими изменениями.</p>
				<p>8.6. В случае если отдельные нормы настоящей Политики вступят в противоречие с действующим законодательством Российской Федерации, они утрачивают силу, и применяются соответствующие нормы законодательства Российской Федерации. Недействительность отдельных норм настоящей Политики не влечет недействительность других норм и Политики целом.</p>
			</div>
		</div>
	</section>
</template>

<script>
export default {
    mounted() {
        this.$scrollTo(document.querySelector('header.header-role'))
    }
}
</script>

<style scoped>
	li::before {
		display: none!important;
	}
</style>
